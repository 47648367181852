<template>
    <v-container>
        <!-- Dialog for success or error messages -->
        <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card>
                <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
                <v-card-text>{{ dialogMessage }}</v-card-text>
                <v-card-actions>
                    <v-btn color="blue" text @click="dialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import alert from "@/mixins/alert";  // Esto se puede mantener si tienes algún otro uso del mixin alert
import Api from "@/utils/api";
import moment from "moment";

export default {
    data() {
        return {
            infoToken: {
                document: "",
            },
            // Para controlar el estado del dialog
            dialog: false,
            dialogTitle: "",
            dialogMessage: "",
        };
    },
    methods: {
        validateToken() {
            var code = this.$route.query.code;
            Api.noAuth()
                .loginOffice(code)
                // .then((res) => res.json())
                .then((res) => {
                    if (res.status === 200) {
                        res.json().then((res) => {
                            const token = res.data;
                            localStorage.setItem("token", atob(token));  // Asegúrate de que 'token' está definido
                            localStorage.setItem("tokenB64", token);  // Asegúrate de que 'token' está definido
                            this.saveLog();  // Guarda el log
                            this.$router.push({ name: "home" });  // Redirige a la página principal

                            // Mostrar mensaje de éxito en el dialog
                            // this.showDialog("Éxito", "Token validado correctamente y redirigiendo...");
                        })
                    } else {
                        res.json().then((res) => {
                            this.showDialog("Error", res.message);
                        })
                    }
                })
                .catch((error) => {
                    // Mostrar error usando el dialog de Vuetify
                    console.error("Error al validar el token:", error);
                    this.showDialog("Error", `Error al validar el token: ${error.message || error}`);
                }
                );
        },

        // Método para mostrar el dialog
        showDialog(title, message) {
            this.dialogTitle = title;
            this.dialogMessage = message;
            this.dialog = true;  // Abre el dialog
        },

        getToken() {
            var token = localStorage.getItem("token");
            var div = token.split(".");
            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
            } else {
                this.infoToken = {
                    document: "",
                };
            }
        },

        saveLog() {
            this.getToken();
            let dispositivo;
            if (screen.width < 1024) {
                dispositivo = "Mobile";
            } else {
                dispositivo = "Desktop";
            }
            const record = {
                userid: this.infoToken.document,
                appid: "79",
                appname: "Login Okan",
                date: moment().format("YYYY-MM-DD"),
                dispositivo,
            };

            // Api.noAuth()
            //   .saveAppActivity(record)
            //   .then((resp) => resp.json())
            //   .then((data) => {} )
            //   .catch((error) => {
            //     alert.error("Error al guardar el registro de actividad: " + error.message);
            //   });
        },
    },
    mounted() {
        this.validateToken();
    },
};
</script>

<style scoped>
/* Estilo opcional para personalizar el dialog */
</style>